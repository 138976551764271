<template>
  <div>
    <MyHeader :title="$t('lang.error404')" />
    <div>{{ $t("lang.pagenotfound") }}</div>
    <router-link to="/">{{ $t("lang.backhome") }}</router-link>
  </div>
</template>

<script>
import MyHeader from "@/components/Header.vue";
export default {
  components: { MyHeader },
};
</script>
